import React from "react"
import { graphql } from "gatsby"
import statWinner from "../../js/statWinner"
//import formatNumComma from "../../js/formatNumComma"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import InfoBar from '../../components/Utilities/InfoBar'

class AerialDuels extends React.Component {

  render() {

    const pageTitle = "Aerial Duels"

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    const subStat = (x, comp) => {
      return x === "m" ? (
        parseFloat(mdata.filter(d => d.node.competition === comp).map(d => d.node.aerialDuels)[0] / (mdata.filter(d => d.node.competition === comp).map(d => d.node.minsPlayed)[0] / 90)).toFixed(2)
      ) : (
        parseFloat(rdata.filter(d => d.node.competition === comp).map(d => d.node.aerialDuels)[0] / (rdata.filter(d => d.node.competition === comp).map(d => d.node.minsPlayed)[0] / 90)).toFixed(2)
      )
    }

    const comps = [
      {
        "comp": "League + Champs League",
        "mSubStat": subStat('m', 'League and Europe Since 09'),
        "rSubStat": subStat('r', 'League and Europe Since 09'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "League Since 09",
        "mSubStat": subStat('m', 'League Since 09'),
        "rSubStat": subStat('r', 'League Since 09'),
        "btnText": "Season Breakdown",
        "title": "League"
      },
      {
        "comp": "Europe Since 09",
        "mSubStat": subStat('m', 'Europe Since 09'),
        "rSubStat": subStat('r', 'Europe Since 09'),
        "btnText": "Season Breakdown",
        "title": "Champions League"
      },
    ]

    return (

      <Layout>
        <SEO
          title={`${pageTitle} - Messi vs Ronaldo Career ${pageTitle} Stats`}
          description={`Ronaldo is one of the best headers of the ball in history, but how many aerial duels does he win per match? And how does Messi compare? We provide all the ${pageTitle} stats in detail.`}
          canonicalPath={`/detailed-stats/aerial-duels/`}
        />

        <h1>{pageTitle} <span className="sr-only">- Messi vs Ronaldo All Time {pageTitle} Stats</span></h1>

        <div className="flex items-center justify-center mt-8 lg:mt-10 xl:mt-12 max-w-xs mb-2 xl:mb-4 mx-auto px-4">
          <InfoBar classes="w-full justify-center" msg="Non-European league stats not included in totals" />
        </div>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center`}>

          {comps.map(d => (
            <DetailedStatBars
              key={d.comp}
              data={this.props.data}
              competition={d.comp}
              playerTitleHidden={`${d.comp} ${pageTitle} since 09/10`}
              statType={pageTitle}
              btnText={d.btnText}
              statView={['Totals', "Per 90 mins"]}
              mSubStat={`wins ${d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-'} aerial duels every 90 minutes`}
              rSubStat={`wins ${d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-'} aerial duels every 90 minutes`}
              subStatW={statWinner(d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-', d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-')}
              since="09/10"
              title={d.title}
            />
          ))}

        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          apps
          minsPlayed
          aerialDuels
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          apps
          minsPlayed
          aerialDuels
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          aerialDuels
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          aerialDuels
        }
      }
    }
    allSheetMessiIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          aerialDuels
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          aerialDuels
        }
      }
    }
  }
`

export default AerialDuels
